import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState , Suspense  } from "react";
import { useSelector , useDispatch } from "react-redux";
import { extractUser } from "../store/Slices/userAuthSlice";
import Cookies from 'js-cookie';
import STATUSES from "../store/Statuses";
import Loader from "../components/Common/Loader/Loader";
import ApiFields from "../components/Traceability/Settings/ApiFields/ApiFields";

const Homepage = React.lazy(() => import("../pages/Homepage"));
const Ekyc  = React.lazy(() => import("../components/Ekyc"));
const ErrorPage  = React.lazy(() => import("../pages/ErrorPage"));
const HomeComponents  = React.lazy(() => import("../components/HomeComponents"));
const Login  = React.lazy(() => import("../components/HomeComponents/Login/Login"));
const SighUp  = React.lazy(() => import("../components/HomeComponents/Login/SignUp"));
const RequestPage  = React.lazy(() => import("../components/HomeComponents/RequestPage/RequestPage"));

const MarketMapsPage  = React.lazy(() => import("../pages/MarketMapsPage/MarketMapsPage"));

const PMSpage  = React.lazy(() => import("../pages/PMSpage/PMSpage"));
const UserProfile  = React.lazy(() => import("../components/Common/User/UserProfile/UserProfile"));
const PMShome  = React.lazy(() => import("../components/PMS/PMSallPages/PMShome/PMShome"));
const UserProfileEdit  = React.lazy(() => import("../components/Common/User/UserProfileEdit/UserProfileEdit"));
const AllEmployee  = React.lazy(() => import("../components/Common/User/ALLEmployee/AllEmployee"));
const AddEmployeeProfile  = React.lazy(() => import("../components/Common/User/AddEmployeeProfile/AddEmployeeProfile"));
const ImportEmployees  = React.lazy(() => import("../components/Common/User/ImportEmployees/ImportEmployees"));
const UserPersonalSetting  = React.lazy(() => import("../components/Common/User/UserPersonalSetting/UserPersonalSetting"));
const ProductTableHome  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllTables/ProductTable/ProductTableHome"));
const AddProductTable  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllTables/ProductTable/AddProductTable"));
const UserPage  = React.lazy(() => import("../pages/UserPage/UserPage"));
const RetailerTableHome  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/RetailerTableHome"));
const AddRetailerTable  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/AddRetailerTable"));
const AddManufacturerTable  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/AddManufacturerTable"));
const ManufacturerTableHome  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/ManufacturerTableHome"));
const SupplierTableHome  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/SupplierTableHome"));
const AddSupplierTable  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/AddSupplierTable"));
// import PackagingTableHome from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/PackagingTableHome";
// import AddPackagingTable from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/AddPackagingTable";
// import AddPromotionTable from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/AddPromotionTable";
// import PromotionTablehome from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/PromotionTablehome";

// import RegionTableHome from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/RegionTableHome";
// import AddRegionTable from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/AddRegionTable";
// import GroupTableHome from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/GroupTableHome";
// import AddGroupTable from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/AddGroupTable";

const SettingHome  = React.lazy(() => import("../components/MarketMaps/Settings/SettingHome/SettingHome"));
const ProgramHome  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/Program/ProgramHome/ProgramHome"));

// All Charts from market map
const Barchart  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllCharts/Barchart/Barchart"));
const Areachart  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllCharts/Areachart/Areachart"));
const Linechart  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllCharts/Linechart/Linechart"));
const Radarchart  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllCharts/Radarchart/Radarchart"));
const Vbarchart  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllCharts/Vbarchart/Vbarchart"));
const Piechart  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllCharts/Piechart/Piechart"));
const BubbleChart  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllCharts/BubbleChart/BubbleChart"));
const StackedBarChart  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/AllCharts/StackedBarChart/StackedBarChart"));

const AddNewProgram  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/Program/AddNewProgram/AddNewProgram"));
const MarketMapHome  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/MarketMapHome/MarketMapHome"));
// import EditProduct from "../components/MarketMaps/MarketAllPages/AllTables/ProductTable/EditProduct";
// import EditRetailer from "../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/EditRetailer";
// import EditManufacturer from "../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/EditManufacturer";
// import EditSupplier from "../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/EditSupplier";
// import EditPackaging from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/EditPackaging";
// import EditPromotion from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/EditPromotion";
// import EditRegion from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/EditRegion";
// import EditGroup from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/EditGroup";
// import ViewAllPrograms from "../components/MarketMaps/MarketAllPages/Program/ViewAllPrograms/ViewAllPrograms";
const ProductAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/ProductAtt"));
const RetailerAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/RetailerAtt"));
const ManufacturerAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/ManufacturerAtt"));
const SupplierAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/SupplierAtt"));
const PackagingAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/PackagingAtt"));
const MarketingCallOutAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/MarketingCallOutAtt"));
const ProgramAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/ProgramAtt"));
const RegionAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/RegionAtt"));
const GroupAtt  = React.lazy(() => import("../components/MarketMaps/Settings/AllAttributes/GroupAtt"));
const EditProgram  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/Program/EditProgram/EditProgram"));
const EditOneProgram  = React.lazy(() => import("../components/MarketMaps/MarketAllPages/Program/EditOneProgram/EditOneProgram"));
const ProfileUpdate  = React.lazy(() => import("../components/HomeComponents/ProfileUpdate/ProfileUpdate"));
const TraceabilityPage  = React.lazy(() => import("../pages/TraceabilityPage/TraceabilityPage"));
const TraceabilityHome  = React.lazy(() => import('../components/Traceability/AllPages/HomePage/TraceabilityHome'));
const SupplyChainVisualization  = React.lazy(() => import("../components/Traceability/AllPages/SupplyChainVisualization/SupplyChainVisualization"));
const OrderTracking  = React.lazy(() => import("../components/Traceability/AllPages/OrderTracking/OrderTracking"));
const TraceabilityTree  = React.lazy(() => import("../components/Traceability/AllPages/TraceabilityTree/TraceabilityTree"));
const ReportComponent  = React.lazy(() => import("../components/Common/User/ALLEmployee/ReportComponent/ReportComponent"));
const AccessTableList  = React.lazy(() => import("../components/Common/User/ALLEmployee/AccessTableList/AccessTableList"));
const TraceabilitySetUp  = React.lazy(() => import("../components/Traceability/Settings/TraceabilitySetUp/TraceabilitySetUp"));
const POtracking = React.lazy(() => import("../components/Traceability/Settings/POtracking/POtracking"));
const StageSet = React.lazy(() => import("../components/Traceability/Settings/StageSetUp/StageSet"));
const ContainerTracking = React.lazy(() => import("../components/Traceability/AllPages/ContainerTracking/ContainerTracking"));




function MyRoutes() {
  const dispatch = useDispatch();
  const [showExpirationWarning, setShowExpirationWarning] = useState(false);

  // console.log("companyName", companyName);
  // console.log("Server name=", process.env.REACT_APP_ENV_NAME);


  useEffect(() => {
    // Function to check token expiration and handle cleanup
    const checkTokenExpiration = () => {
      const storedExpirationTime = localStorage.getItem('onifiedPKexpirationTime');
      const currentTime = new Date().getTime();
      const fiveMinutesBeforeExpiration = storedExpirationTime - 5 * 60 * 1000;
  
      if (storedExpirationTime && currentTime > storedExpirationTime) {
        // Clear expired data
        Cookies.remove('onifiedPKtoken');
        localStorage.removeItem('onifiedPKData');
        localStorage.removeItem('onifiedPKexpirationTime');
  
        // Redirect to login page or show an appropriate message
        window.location.href = '/login';
      }
      else if (storedExpirationTime && currentTime > fiveMinutesBeforeExpiration) {
        // Show popup warning 5 minutes before expiration
        setShowExpirationWarning(true);
      }
    };
  
    // Call the function immediately on component mount
    checkTokenExpiration();
  
    // Set up an interval to check for token expiration every minute
    const interval = setInterval(() => {
      console.log("check ")
      checkTokenExpiration();
    }, 60000); // 60000ms = 1 minute
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);
  
  

  const { token, userDetails } = useSelector((state) => state.auth);
  const { data: user, status: userStatus , error} = useSelector((state) => state.ExtractUser);


  // console.log("user===", user);


  useEffect(()=>{
    if(token?.length>0 && userDetails){
      dispatch(extractUser());
    }
  },[dispatch ,token ,userDetails]);

  if (token?.length>0 && userStatus === STATUSES.IDEAL) {
    return <Loader/>;  
  }
  if (userStatus === STATUSES.LOADING) {
    return <Loader/>;  
  }

  if (error === STATUSES.LOADING) {
    return <Loader/>;  
  }


  return (
    <BrowserRouter>
     {showExpirationWarning && (
      <>
      <div className="blur-overlay"></div>
      <div className="expiration-warning-popup">
        <p>
          Session Expiring Soon! You will be logged off in 5 minutes. Save your
          work and log off, or log back in to extend your session for 48 hours.
        </p>
        <button onClick={() => setShowExpirationWarning(false)}>OK</button>
      </div>
      </>
    )}
      <Suspense fallback={<Loader/>}>
      <Routes>
        {/* Home  */}
        <Route path="/" element={<Homepage />}>
          <Route index path="/" element={<HomeComponents />} />
          <Route path="/ekyc" element={<Ekyc />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SighUp />} />
          <Route path="/request" element={<RequestPage />} />
        </Route>

        {/* Market Map */}
        {
        token && userDetails && user && Object.keys(user).length >0? (
          <>
        <Route path="market-map" element={<MarketMapsPage />}>
          <Route index path="" element={<MarketMapHome />} />

          {/* All Charts  */}
          {
              user?.role?.roleName === "ADMIN" ? (
                 <>
                     <Route path="Bar-Chart" element={<Barchart />} />
                     <Route path="Pie-Chart" element={<Piechart />} />
                     <Route path="Area-Chart" element={<Areachart />} />
                     <Route path="Bubble-Chart" element={<BubbleChart />} />
                     <Route path="Line-Chart" element={<Linechart />} />
                     <Route path="Radar-Chart" element={<Radarchart />} />
                     <Route path="Vertical-Bar-Chart" element={<Vbarchart />} />
                     <Route path="Stacked-Bar-Chart" element={<StackedBarChart />} />
                 </>
               
            ) :null
          }
          
          
          {/* <Route  path='Add-List' element={<AddList/>}/> */}

          {/* <Route path="Tables" element={<AddList />} /> */}

          {/* All table pages */}
          <Route path="Tables/Product-Table" element={<ProductTableHome />} />
          <Route path="Tables/Product-Table/Add-Product" element={<AddProductTable />} />
          {/* <Route path="Tables/Product-Table/Edit-Product" element={<EditProduct/>}/> */}

          <Route path="Tables/Retailer-Table" element={<RetailerTableHome />} />
          <Route path="Tables/Retailer-Table/Add-Retailer" element={<AddRetailerTable />} />
          {/* <Route path="Tables/Retailer-Table/Edit-Retailer" element={<EditRetailer/>}/> */}

          <Route path="Tables/Manufacturer-Table" element={<ManufacturerTableHome />} />
          <Route path="Tables/Manufacturer-Table/Add-Manufacturer" element={<AddManufacturerTable />} />
          {/* <Route path="Tables/Manufacturer-Table/Edit-Manufacturer" element={<EditManufacturer/>}/> */}

          <Route path="Tables/Supplier-Table" element={<SupplierTableHome />} />
          <Route path="Tables/Supplier-Table/Add-Supplier" element={<AddSupplierTable />} />
          {/* <Route path="Tables/Supplier-Table/Edit-Supplier" element={<EditSupplier/>}/> */}

          {/* <Route path="Tables/Packaging-Table" element={<PackagingTableHome />} />
          <Route path="Tables/Packaging-Table/Add-Packaging" element={<AddPackagingTable />} /> */}
          {/* <Route path="Tables/Packaging-Table/Edit-Packaging" element={<EditPackaging/>}/> */}

          {/* <Route path="Tables/Promotion-Table" element={<PromotionTablehome />} />
          <Route path="Tables/Promotion-Table/Add-Promotion" element={<AddPromotionTable />} /> */}
          {/* <Route path="Tables/Promotion-Table/Edit-Promotion" element={<EditPromotion/>}/> */}

          {/* <Route path="Tables/Region-Table" element={<RegionTableHome />} />
          <Route path="Tables/Region-Table/Add-Region" element={<AddRegionTable />} /> */}
          {/* <Route path="Tables/Region-Table/Edit-Region" element={<EditRegion/>}/> */}

          {/* <Route path="Tables/Group-Table" element={<GroupTableHome />} />
          <Route path="Tables/Group-Table/Add-Group" element={<AddGroupTable />} /> */}
          {/* <Route path="Tables/Group-Table/Edit-Group" element={<EditGroup/>} 
          
          {/* programs  */}
          <Route path="programs" element={<ProgramHome />} />
          <Route path="programs/add-New-Program" element={<AddNewProgram />} />
          <Route path="programs/view-All-Programs" element={<EditProgram />}/>
          {/* <Route path="programs/edit-All-Programs" element={<EditProgram />} /> */}

          <Route path="programs/view-All-Programs/edit-One-Program" element={<EditOneProgram />}/>

          {/* Settings  */}
          <Route path="market-Map-Settings" element={<SettingHome />} />

          {/* {Attributes Setting} */}
          <Route path="market-Map-Settings/Add-Product-Attributes" element={<ProductAtt />} />
          <Route path="market-Map-Settings/Add-Retailer-Attributes" element={<RetailerAtt />} />
          <Route path="market-Map-Settings/Add-Manufacturer-Attributes" element={<ManufacturerAtt />} />
          <Route path="market-Map-Settings/Add-Supplier-Attributes" element={<SupplierAtt />} />
          <Route path="market-Map-Settings/Add-Packaging-Details-Attributes" element={<PackagingAtt />} />
          <Route path="market-Map-Settings/Add-Marketing-Callout-Attributes" element={<MarketingCallOutAtt />} />
          <Route path="market-Map-Settings/Add-Program-Attributes" element={<ProgramAtt />} />
          <Route path="market-Map-Settings/Add-Region-Attributes" element={<RegionAtt />} />
          <Route path="market-Map-Settings/Add-Groups-Attributes" element={<GroupAtt />} />

          <Route path="users-management-Users" element={<AllEmployee />} />
          <Route path="users-management-Report" element={<ReportComponent />} />
          <Route path="users-management-Access-rights" element={<AccessTableList />} />


          <Route path="update-profile" element={<ProfileUpdate />} />
        </Route>

        <Route path="traceability" element={<TraceabilityPage />}>
          <Route index path="" element={<TraceabilityHome />} />
          <Route index path="order-status" element={<OrderTracking />} />
          <Route index path="traceability-tree" element={<TraceabilityTree />} />
          <Route index path="track-container" element={<ContainerTracking />} />
          <Route index path="supply-chain-visualization" element={<SupplyChainVisualization />} />
          
          <Route index path="stage-and-operations-settings" element={<StageSet />} />
          <Route index path="traceability-settings" element={<TraceabilitySetUp />} />
          <Route index path="po-tracking-settings" element={<POtracking />} />
          <Route index path="api-fields-settings" element={<ApiFields />} />
        </Route>
          </>
         ) : (
            <Route path="market-map" element={<Navigate to="/login" />} />
            
           )
        } 

        {/* PMS  */}
        <Route path="pms" element={<PMSpage />}>
          <Route index path="" element={<PMShome />} />
        </Route>


        {/* User Profile  */}
        <Route path="profile" element={<UserPage />}>
          {/* <Route index path="" element={<PMShome/>}/>   */}
          <Route index path="" element={<UserProfile />} />
          <Route path="editProfile" element={<UserProfileEdit />} />
          <Route path="userPersonalSetting" element={<UserPersonalSetting />} />
          <Route path="allEmployees/addNewProfile" element={<AddEmployeeProfile />} />
          <Route path="allEmployees/importEmployees" element={<ImportEmployees />} />
        </Route>


        {(token && userDetails )?
         (
          userStatus === STATUSES.SUCCESS && (
            <Route path="*" element={<ErrorPage />} />
          ) 
         ):
          <Route path="*" element={<ErrorPage />} /> 
         }
      </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default MyRoutes;